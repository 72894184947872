html {
    font-size: 1.2em;
}

body {
    max-width: 90ch;
}

.top-nav {
    text-align: center;
    max-width: 500px;
    margin: 0 auto 2rem auto;
    font-size: 1rem;
}

h1 {
    font-size: 2.3rem;
}

h2 {
    font-size: 1.7rem;
}

h3 {
    font-size: 1.3rem;
}

ul {
    padding-left: 3rem;

    li {
        padding-left: 1rem;
    }
}

figure {
    margin-top: 1rem;
}

a.current {
    font-weight: bold;
}

a.post-preview {
    text-decoration-line: none;
}

a.post-preview .post-title {
    text-decoration-line: underline;
    text-decoration-skip-ink: auto;
    text-decoration-thickness: 1px;
    text-underline-offset: 0.15em;
}

a.post-preview .post-description {
    color: rgb(27, 24, 24);
}

.blog-posts-tags-switch {
    text-align: center;
}

@media (max-width: 768px) {
    html {
        font-size: 1.1em;
    }

    // smaller left paddings to keep text lines wide enough
    ul {
        padding-left: 1.5rem;

        li {
            padding-left: 0;
        }
    }
}

@media (max-width: 600px) {
    html {
        font-size: 1.03em;
    }

    p {
        // 'justify' doesn't look good with too few words per line
        text-align: left;
    }
}

